.my-form {
  .MuiFormControl-root {
    margin-top: 20px;
    margin-right: 20px;
  }
  .MuiButton-root {
    margin-top: 20px;
    margin-right: 20px;
  }
}

.billing-plan-list {
  display: flex;
  justify-content: space-around;
  padding-top: 2vh;
  flex-wrap: wrap;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  .billing-plan-item {
    position: relative;
    // width: 300px;
    h3 {
      margin-top: unset;
    }
    border-radius: 10px;
    padding: 20px;
    margin: 0 20px 20px 0;
    border: rgba(0,0,0,0.5);
    box-shadow: 0 3px 10px rgba(0,0,0,0.5);
    cursor: pointer;
    &:hover {
      box-shadow: 0 3px 10px red;
      transition: box-shadow 0.3s ease-in-out;
    }

    p {
      margin: unset;
    }

    .plan-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .plan-items p {
      font-size: 18px;
      @media (max-width: 425px) {
        font-size: 12px;
      }
    }

    .plan-header {
      font-size: 25px;
      font-weight: bold;
      // color: #16056B;
      margin: 10px 0px 0px 0px;
    }

    .plan-cost {
      font-size: 80px;
      @media (max-width: 425px) {
        font-size: 25px;
      }
      @media (max-width: 700px) {
        font-size: 50px;
      }
      margin: 0px 5px 5px 15px;
      text-align: center;
      span {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  .billing-plan-item.active {
    color: white;
    cursor: default;
    box-shadow: 0 3px 10px rgba(10,100,0,0.5);
    background-color: rgba(10,100,0,0.5);
  }
  .billing-plan-item.next-bp {
    position: relative;

  }
  .next-bp-block {
    position: absolute;
    display: flex;
    top: 5px;
    right: 5px;
    padding: 2px 3px;
    box-shadow: 0 3px 10px rgba(10,100,0,0.5);
    border-radius: 5px;
    .cancel {
      display: none;
      position: absolute;
      right: 0px;
      width: 100%;
      text-align: center;
    }
    &:hover {
      .scheduled {
        opacity: 0;
      }
      .cancel {
        display: block;
        :hover {
          display: block;
        }
      }
    }
  }
  .payed-BP {
    display: none;
    position: absolute;
    width: 280px;
    padding: 20px;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    height: 260px;
    span {
      text-align: center;
    }
  }
  .billing-plan-item.next-scheduled {
    &:hover {
      color: white;
      .payed-BP {
        color: black;
        display: flex;
      }
    }
  }
}
.non-payed {
  background-color: rgba(255, 0, 0, 0.1);
  box-shadow: 0 3px 10px red;
  border-radius: 10px;
  padding: 5px 20px;
  margin: 20px 0px;
  display: flex;
  flex-flow: row;
  align-items: center;
  p {
    margin-left: 10px;
  }
}

.main-transactions-block {
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: center;
}
.main-page-blocks-wrapper {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;

  .main-page-block {
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    // border: 1px solid black;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    max-width: 354px;
    // max-height: 300px;
    // min-width: 350px;
    width: 100%;
    margin: 10px;
    padding-bottom: 20px;
    // padding-left: 20px;
    // padding-right: 20px;
    text-align: center;

    h3 {
      margin-top: 0px;
    }

    .pfont {
      padding: 10px;
      font-size: 20px;
      margin-top: 20px;
    }

    .pfont p {
      font-style: italic;
      color: rgba(0, 0, 0, 0.45);
    }

    .plan-heading {
      background-color: #2d3446;
      width: 100%;
      border-radius: 10px 10px 0px 0px;
      padding: 10px 0;
      margin: 0;
      color: #fff;
    }

    .cost-amnt {
      margin: 0px;
      font-size: 60px;
      text-shadow: 3px 2px 2px #283d3b38;
      // text-align: center;
      span{
        font-size:20px;
      } 
    }

    .plan-name {
      font-style: italic;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.45);
      margin: 0px;
    }

    .margin10 {
      margin: 16px 0px;
    }

    .plan-dets {
      font-size: 20px;
      p {
        margin-top: 10px;
      }
    }
  }
  .disabled-block {
    opacity: 0.4;
  }
  .current-plan-item {
    h4 {
      margin: 0px;
    }
    p {
      margin: 0px;
    }
  }
  span.value {
    font-weight: 600;
  }
  .change-paln-btn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }
  .connected {
    margin: 0px;
    font-weight: bold;
    font-size: 20px;
    // color: #6253e1;
    color: limegreen;
  }
}

.wifi-svg {
  width: 195px;

  @keyframes anim-signal {
    0% {fill: transparent;}
    65% {fill: #14e1ff;}
    100% {fill:transparent;}
  }
  @keyframes anim-beamz {
    0% {fill: transparent;}
    65% {fill: #dffbff;}
    100% {fill:transparent;}
  }
  #beamz > path, #wifi-signal > path{
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
  #wifi-signal > path{
    animation-name: anim-signal;
  }
  #beamz > path{
    animation-name: anim-beamz;
  }
  #beamz path:nth-child(2), #wifi-signal > path:nth-child(2){
    animation-delay: 0.3s;
  }
  #beamz path:nth-child(3), #wifi-signal > path:nth-child(3){
    animation-delay: 0.6s;
  }
  #beamz path:nth-child(4){
    animation-delay: 0.7s;
  }
}

.ant-table-thead .ant-table-cell {
  background: #6253e1 !important;
  color: #fff !important;
}

.avatar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(73, 190, 255, 0.125);
  flex: 0 0 200px;
  max-width: 200px;
  width: 200px;
  margin: 15px 3px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;

  @media (max-width: 1200px) {
    display: none;
  }
}

.accountForm {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wifi-login {
  color: #fff;
  background: #1677ff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  font-size: 16px;
  line-height: 1.5;
  height: 40px;
  padding: 7px 15px;
  border-radius: 8px;
  border: none;
}


