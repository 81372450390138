.react-confirm-alert-blur {
  position: relative;
  height: 100vh;
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #2d34459e;
  }
}
.confirm-modal-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.confirm-modal {
  // padding: 30px;
  background-color: white;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: 0 10px;

  .modal-title {
    padding: 15px;
    font-size: 18px;
    font-weight: 500;
    max-width: 400px;
    text-align: center;
  }
  .modal-actions {
    padding: 10px;
    .main-button {
      margin-right: 5px;
    }
    .cancel-button {
    }
  }
}
